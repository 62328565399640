export const IS_PRODUCTION = process.env.NODE_ENV === 'production'

export const HTTP_HEADER_SKIP_ERROR = 'Winner-Skip-Error'
export const HTTP_STATUS = {
  badRequest: 400,
  unauthrized: 401,
  unprocessableEntity: 422
}

export const STORAGE_ITEM = {
  authToken: 'winner_auth_token'
}

export const AUTH_COOKIE = {
  name: 'winner_auth_token',
  expires: (1 / 1440 * process.env.VUE_APP_LOGIN_TIME) || (1 / 12),
  path: '/'
}

export const LANG_COOKIE = {
  name: 'winner_lang',
  path: '/'
}

export const BOARD_COOKIE = {
  name: 'winner_boardList',
  path: '/'
}

// if (IS_PRODUCTION) {
// AUTH_COOKIE.domain = '.naver.com'
// }

export const GAME_CATEGORY = [
  // { name: '전체', icon: require('@/assets/img/all.png'), value: 'all', use: true },
  { name: 'front.gnb.livecasino', icon: require('@/assets/img/live_casino.png'), value: 'casino', use: true },
  { name: 'front.gnb.slot', icon: require('@/assets/img/slot.png'), value: 'slot', use: true },
  { name: 'front.gnb.sport', icon: require('@/assets/img/sports.png'), value: 'sport', use: true },
  { name: 'front.gnb.minigame', icon: require('@/assets/img/minigame.png'), value: 'minigame', use: true }
  //  { name: 'e-스포츠', icon: require('@/assets/img/e_sports.png'), value: 'esport', use: true },

//  { name: '가상게임', icon: require('@/assets/img/virtualgame.png'), value: 'kiron', use: true }
  // { name: '피싱게임', icon: require('@/assets/img/fishinggame.png'), value: 'fishing', use: true }
  // ,{ name: '로터리게임', icon: require('@/assets/img/rotary.png'), value: 'rotary', use: true }
]

export const GAME_DETAIL_INFO = {
  evolution: 1,
  biggaming: 2,
  asiagaming: 5,
  dreamgaming: 6,
  sexygaming: 9,
  pragmaticplay: 10,
  playtech: 12,
  hotelcasino: 14,
  slot: 0,
  pragmaticslot: 200,
  hananero: 201,
  elysium: 202,
  fishing: 1000
}

export const SPORT_GAME_CATEGORY = [
  { value: 'all', name: '전체', use: true, default: true, img: require('@/assets/img/all.png') },
  { value: 'soccer', name: '축구', use: true, img: require('@/assets/img/sports.png') },
  { value: 'basketball', name: '농구', use: true, img: require('@/assets/img/basket.png') },
  { value: 'baseball', name: '야구', use: true, img: require('@/assets/img/base.png') },
  { value: 'volleyball', name: '배구', use: true, img: require('@/assets/img/volley.png') },
  { value: 'badminton', name: '배드민턴', use: true, img: require('@/assets/img/tennis.png') },
  { value: 'hockey', name: '하키', use: true, img: require('@/assets/img/hockey2.png') },
  { value: 'combat', name: '격투기', use: true, img: require('@/assets/img/betting.png') }
]

export const SPORT_GAME_CATEGORY2 = [
  { value: 'soccer', name: '축구', use: true, img: require('@/assets/img/sports.png'), imgs: true },
  { value: 'basketball', name: '농구', use: true, img: require('@/assets/img/basket.png'), imgs: true },
  { value: 'baseball', name: '야구', use: true, img: require('@/assets/img/base.png'), imgs: true },
  { value: 'volleyball', name: '배구', use: true, img: require('@/assets/img/volley.png'), imgs: true },
  { value: 'tennis', name: '테니스', use: true, img: require('@/assets/img/tennis.png'), imgs: true },
  { value: 'hockey', name: '하키', use: true, img: require('@/assets/img/hockey2.png'), imgs: true },
  { value: 'handball', name: '핸드볼', use: true, img: require('@/assets/img/handball.png'), imgs: true },
  { value: 'football', name: '미식축구', use: true, img: require('@/assets/img/rugby.png'), imgs: true },
  { value: 'esport', name: 'e-스포츠', use: true, img: require('@/assets/img/e_sports.png'), imgs: true }
]

export const DOCUMENT_TITLE_PREFIX = process.env.VUE_APP_TITLE || ''

export const CASH_STATUS = {
  0: {
    class: 'poss',
    text: '신청'
  },
  1: {
    class: 'blc',
    text: '완료'
  },
  2: {
    class: '',
    text: '대기'
  },
  '-1': {
    class: '',
    text: '취소'
  }
}

export const CASH_STATUS_MOBILE = {
  0: {
    class: 'poss',
    text: '신청'
  },
  1: {
    class: 'done',
    text: '완료'
  },
  2: {
    class: '',
    text: '대기'
  },
  '-1': {
    class: 'cancel',
    text: '취소'
  }
}

export const PARTNER_LEVEL_NAME = {
  PTN_1: '총본사',
  PTN_2: '대본사',
  PTN_3: '부본사',
  PTN_4: '총판',
  PTN_5: '매장',
  NORMAL: '회원'
}

export const SOCKET_SERVER_URL = process.env.VUE_APP_WEB_SOCKET_URL || 'http://54.168.76.109/websocket'
export const SOCKET_SUBSCRIBE_DESTINATION = '/topic/'
export const SOCKET_POWERBALL_SUBSCRIBE_DESTINATION = '/topic/powerball/'

export const TRIPLE_GAME_LIST = {
}

export const NonIframeGameCode = [
  '1',
  '13' // bota
]

export const PARTNER_MOBILE_TABLE_SITE = [
  'brown789'
]
