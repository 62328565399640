<template>
  <footer>
    <div class="wrapper">
      <!--div class="bottom-img"></div-->
      <div class="nav-bottom">
        <div class="footerInfo">
          <div class="footerl">
            <a class="flogo" @click="goPageByName('main')">
              <img src="@/assets/img/apluslogo.svg" />
            </a>
            <ul class="footerNav">
              <li @click="goPageByName('main')">{{ $t('front.gnb.home')}}</li>
              <li @click="goPageByName('')">{{ $t('front.gnb.casino')}}</li>
              <li @click="goPageByName('')">{{ $t('front.gnb.slot')}}</li>
              <li @click="goPageByName('charge')">{{ $t('front.stributor.dnws')}}</li>
              <li @click="goPageByName('boardList')">{{ $t('front.common.board')}}</li>
              <li @click="goPageByName('faq')">{{ $t('front.common.faq')}}</li>
            </ul>
          </div>
          <div class="footerr">
             <ul class="help">
               <li>@ www.BATMAN.com</li>
               <li>
                 <img src="@/assets/img/icon_help.svg" />
                 <span>도움이 필요하신가요?</span>
               </li>
             </ul>
            <ul class="sns" v-if="siteOption">
              <li @click="siteOption['sns2Link']">
                <img src="@/assets/img/telegram.svg" />
                <span>{{siteOption['sns2']}}<em>{{siteOption['sns2Id']}}</em></span>
              </li>
              <li @click="siteOption['sns1Link']">
                <img src="@/assets/img/kakao.svg" />
                <span>{{siteOption['sns1']}}<em>{{siteOption['sns1Id']}}</em></span>
              </li>
              <li v-if="siteOption['sns3']" @click="siteOption['sns3Link']">
                <img src="@/assets/img/zalo.svg" />
                <span>{{siteOption['sns3']}}<em>{{siteOption['sns3Id']}}</em></span>
              </li>
            </ul>
            <span class="copyright">Copyright 2023 MAX. All Rights Reserved.</span>
          </div>
        </div>
      </div>
    </div>

  </footer>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Footer',
  computed: {
    ...mapState([
      'siteOption'
    ])
  }
}
</script>
<style scoped src="@/styles/footer.css"></style>
<style scoped src="@/styles/common.css"></style>
